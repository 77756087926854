import { environment } from "environment";

export const CURRENT_COUNTRY_CACHE_KEY = "cctry"; // Current country cache key
export const COUNTRIES_CACHE_KEY = "ctrs"; // Countries cache key

export const countriesApiUrl = {
  list: "/countries",
  updateMarket: "/users/selected-market",
  getCountryProvinces: (countryCode: string) =>
    `${environment.BACKEND_URL}admin/countries/${countryCode}/provinces`,
  getProvinceZones: (countryCode: string, provinceId: string) =>
    `${environment.BACKEND_URL}admin/countries/${countryCode}/provinces/${provinceId}/zones`,
  getZoneDistricts: (countryCode: string, provinceId: string, zoneId: string) =>
    `${environment.BACKEND_URL}admin/countries/${countryCode}/provinces/${provinceId}/zones/${zoneId}/districts`,
};

export const marketsList = ["eg", "sa", "ae", "iq", "om", "kw", "tt"] as const;
