export enum ImageSize {
  small = "100x100",
  medium = "160x160",
  large = "360x360",
}

/**
 * Takes an image URL and returns a new URL with the specified size dimensions.
 * This utility is used to resize product images to different sizes (small/medium/large)
 * based on where they are displayed in the UI.
 *
 * Example:
 * Input: "https://example.com/300x300/product.jpg", ImageSize.small
 * Output: "https://example.com/100x100/product.jpg"
 *
 * @param originalUrl - The original image URL
 * @param targetSize - Desired image size from ImageSize enum (100x100, 160x160, or 360x360)
 * @returns The URL modified to include the new size dimensions
 */
export const getSizedImage = (
  originalUrl: string,
  targetSize: ImageSize,
): string => {
  // Return empty string if no URL provided
  if (!originalUrl) return "";

  // Regex to split URL into base domain and image path
  const urlParsingRegex = /(.+\.com)\/(.+)/;

  // Remove any existing size dimensions (e.g. "300x300") from URL
  const urlWithoutSize = originalUrl.replace(/\/\d{3}x\d{3}\//g, "/");

  // Match URL against regex to extract parts
  const urlParts = urlWithoutSize.match(urlParsingRegex);

  // Return original URL if it doesn't match expected format
  if (urlParts?.length && urlParts.length < 3) {
    return originalUrl;
  }

  // Extract base URL and image path
  const baseDomain = urlParts![1];
  const imagePath = urlParts![2];

  // Construct new URL with desired size
  const resizedImageUrl = `${baseDomain}/${targetSize}/${imagePath}`;
  return resizedImageUrl;
};
