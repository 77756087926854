import Moengage from "@moengage/web-sdk";
import { GenericObject } from "@taager/reinforcements";
import { environment } from "environment";
import user from "user";

const moengageConfig = {
  app_id: environment.MOENGAGE_APP_ID,
  cluster: environment.MOENGAGE_DATA_CENTER,
  debug_logs: environment.MOENGAGE_DEBUG_LOGS,
};

export class MoEngageManagerService {
  public init(): void {
    Moengage.initialize(moengageConfig);
  }

  public identify() {
    Moengage.add_unique_user_id(user.id);
    Moengage.add_first_name(user.fullName);
    Moengage.add_email(user.email);
    Moengage.add_mobile(user.phoneNumber);
  }

  public destroySession() {
    Moengage.destroy_session();
  }

  public trackEvent(eventName: string, data?: GenericObject) {
    Moengage.track_event(eventName, data);
  }
}

export const moengageService = new MoEngageManagerService();
