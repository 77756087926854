export const USER_LOGIN_CACHE_KEY = "usr";
export const USER_SESSION_KEY = "usr-s";
export const UTM_SESSION_KEY = "utms";

export const userApiUrl = {
  /* Auth Services */
  login: `users/login`,
  forgetPassword: `auth/forgotPassword`,
  resetPassword: (id: string, token: string) =>
    `auth/resetPassword/${id}/${token}`,
  register: `users/register`,
  changePassword: `auth/changePassword`,
  updateProfile: `user/updateProfile`,
  getProfile: `user/viewOwnProfile`,
  requestOTP: `users/phone-number`,
  verifyPhoneNumber: `users/phone-number/verify`,
  socialLogin: `auth/socialAuthSignIn`,
  socialSignup: `users/social-login`,
  completeGoogleSignup: `users/complete-social-signup`,
  requestEmailOTP: `users/email/verification/otp`,
  verifyEmailOtp: `users/email/verification/otp/verify`,
  updateUserProfile: `v2/users/user-profile`,
  merchantShoppingSummary: "merchant/shopping-summary",
  deleteAccount: "v2/users/deactivate",
  addUserUTMData: (userId: number) => `users/${userId}/utm-data`,
};
