import { resolveUTMDataRepository } from "@di/app";
import {
  stringifyObjectValues,
  toCamelCaseObjectKeys,
} from "@features/shared/data";
import { queryString } from "@taager/react-router";
import { only } from "@taager/reinforcements";
import user from "user";
import { UserUTMData } from "../domain";

const utmParameters = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "utm_id",
];

export function detectUtms() {
  const queryParams = queryString.all();

  const utmData: UserUTMData = {
    landingPageUrl: window.location.href,
    timestamp: new Date().toISOString(),
    ...stringifyObjectValues(
      toCamelCaseObjectKeys(only(queryParams, utmParameters)),
    ),
  };

  /** Ensures that all UTM parameters are present in the query string */
  const hasAllUtmParams = Object.keys(utmData).length === 8;

  if (!hasAllUtmParams) {
    return;
  }

  const utmRepository = resolveUTMDataRepository().setData(utmData);

  if (user.isLoggedIn) {
    utmRepository.send();
  } else {
    user.onLogin(() => {
      utmRepository.send();
    });
  }
}
