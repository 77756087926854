import { ProvinceEntity } from "@features/country/domain";
import { ProvinceModel } from "../models/province.model";

export function mapProvince(province: ProvinceModel): ProvinceEntity {
  return {
    id: province.provinceId,
    name: province.name,
    shippingCost: province.shippingCost,
  };
}
