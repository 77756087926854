import { resolveAuthRepository } from "@di/app";
import { captureError } from "@features/activities/data";
import { http } from "@features/drivers/data";
import { parseError } from "@features/shared/data";
import { REDIRECT_URL, URLS } from "@features/shared/presentation";
import cache from "@taager/cache";
import { currentRoute } from "@taager/react-router";
import { GenericObject } from "@taager/reinforcements";
import { navigate } from "app/utils";
import { detectUtms } from "./utils";

http.events.onError(response => {
  // make sure to logout the user if the response status is 401
  // then redirect the user to the login page
  if (response.status === 401) {
    const route = currentRoute();
    cache.set(REDIRECT_URL, route);

    if (!route.includes(`/auth`) && !route.includes(`/reset`)) {
      resolveAuthRepository().logout();
      navigate(URLS.auth.login);
    }
  }

  const payload: GenericObject = {
    response: {
      headers: response.headers,
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    },
    request: {
      url: response.config.url,
      method: response.config.method,
      params: response.config.params,
    },
  };

  captureError(new Error(parseError(response)), payload);
});

detectUtms();
