import { type GenericObject } from "@taager/reinforcements";

/**
 * Remove any falsy value except for 0 ``, null or undefined
 */
export function heavyObject<T extends GenericObject>(obj: T): T {
  if (!obj) return obj;

  const result = {} as T;

  for (const key in obj) {
    const value = obj[key];

    if (Array.isArray(value)) {
      const array = value
        .map(item =>
          typeof item === "object" && item !== null ? heavyObject(item) : item,
        )
        .filter(item => item || item === 0 || item === false) as typeof value;

      if (array.length > 0) {
        result[key] = array;
      }
    } else if (value || value === 0 || value === false) {
      result[key] =
        typeof value === "object" && value !== null
          ? heavyObject(value)
          : value;

      if (
        typeof result[key] === "object" &&
        Object.keys(result[key]).length === 0
      ) {
        delete result[key];
      }
    }
  }

  return result;
}
