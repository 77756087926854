export const __DEV__ = import.meta.env.MODE === "development";
export const defaultLocaleCode = "ar";
export const fallbackLocaleCode = "ar";

export const DEFAULT_REFERRER = "utmSource=taager&utmMedium=login";

export const LOGIN_REDIRECT_TO_ANDROID =
  "https://play.google.com/store/apps/details?id=com.taager.merchant";

export const LOGIN_REDIRECT_TO_IOS =
  "https://apps.apple.com/app/apple-store/id6443435589?pt=125606533&ct=login&mt=8";
