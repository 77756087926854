import {
  QuestionnaireAnswerEntity,
  QuestionnaireEntity,
  QuestionnaireQuestionEntity,
} from "@features/questionnaire/domain";
import {
  QuestionnaireAnswerModel,
  QuestionnaireModel,
  QuestionnaireQuestionModel,
} from "../types/questionnaire.model";

export function mapQuestionnaire(
  questionnaire: QuestionnaireModel,
): QuestionnaireEntity {
  return {
    name: questionnaire.name,
    questions: questionnaire.questions.map(mapQuestionnaireQuestion),
  };
}

export function mapQuestionnaireQuestion(
  question: QuestionnaireQuestionModel,
): QuestionnaireQuestionEntity {
  console.log(question);
  return {
    id: question.id,
    text: {
      ar: question.textAr,
      en: question.textEn,
    },
    answers: question.answers.map(mapQuestionnaireAnswer),
  };
}

export function mapQuestionnaireAnswer(
  answer: QuestionnaireAnswerModel,
): QuestionnaireAnswerEntity {
  return {
    id: answer.id,
    text: {
      ar: answer.textAr,
      en: answer.textEn,
    },
  };
}
