import { moengageService, trackEvent } from "@features/activities/data";
import { mixpanelService } from "@features/activities/data/mixpanel/mixpanel";
import { getCountryIsoCode3FromIsoCode2 } from "@features/country/data";
import { countryAtom } from "@features/country/presentation";
import { BaseRepository, parseError } from "@features/shared/data";
import {
  trackEmailVerificationFailed,
  trackEmailVerificationRequested,
  trackEmailVerificationRequestError,
  trackEmailVerificationSuccess,
  trackGoogleTagCompleteSignUpEvent,
  trackGoogleTagSignUpEvent,
  trackLoginEmailSuccess,
  trackLoginSuccess,
  trackLoginSuccessByGoogle,
  trackLoginSuccessMobileFullyVerified,
  trackLoginSuccessMobileUnverified,
  trackPhoneNumberVerificationFailed,
  trackPhoneNumberVerificationRequested,
  trackPhoneNumberVerificationRequestError,
  trackPhoneNumberVerificationSuccess,
  trackSignUpByEmailSuccess,
  trackSignUpByGoogleSuccess,
  trackSignupCompleteProfileSuccessfully,
  trackSignUpVerificationSuccess,
  UserActivityEvents,
  UserLoginActivityEvents,
} from "@features/user/data";
import { trans } from "@taager/localization";
import { GenericObject } from "@taager/reinforcements";
import {
  AuthRepositoryContract,
  AuthUser,
  CompleteGoogleSignupData,
  CompleteUserProfileData,
  EmailLoginData,
  ForgetPasswordData,
  GetEmailOTPData,
  GetPhoneOTPData,
  GoogleSignInData,
  OTPData,
  PhoneNumberLoginData,
  RegisterData,
  ResetPasswordData,
  RotateRefreshTokenData,
  UpdateJwtData,
  UTMDataRepositoryContract,
  VerifyEmailData,
  VerifyNationalIdData,
  VerifyPhoneNumberData,
} from "../../domain";
import { userApiUrl } from "../constants";
import { mapUserData } from "../mappers";
import { user } from "../services";

// TODO: Implement LoginResponse
type LoginResponse = GenericObject;

export class AuthRepository
  extends BaseRepository
  implements AuthRepositoryContract
{
  public constructor(protected utmDataRepository: UTMDataRepositoryContract) {
    super();
  }

  public async loginByEmail(data: EmailLoginData): Promise<AuthUser> {
    try {
      // Make the HTTP post request to the server with the login form data
      const response = await this.http.post<EmailLoginData, LoginResponse>(
        userApiUrl.login,
        data,
      );

      // Map the response data to an AuthUser object
      const authUser = mapUserData(response.data);

      // Set user cache
      user.login(authUser);

      mixpanelService.identify();

      moengageService.identify();

      trackLoginSuccess();

      trackLoginEmailSuccess();

      // Return the authenticated user
      return authUser;
    } catch (error: any) {
      // Translate the error message
      const translatedError = this.translateError(error);

      throw translatedError;
    }
  }

  // Method to translate the error based on its type
  private translateError(error: any): string {
    if (error.response?.status === 401) {
      return trans("auth.invalidUsernameOrPassword");
    } else if (error.response?.status === 500) {
      return trans("auth.serverError");
    } else {
      return parseError(error);
    }
  }

  /**
   * Login by phone and password
   */
  public async loginByPhone(data: PhoneNumberLoginData): Promise<AuthUser> {
    try {
      // Make the HTTP post request to the server with the login form data
      const response = await this.http.post<
        PhoneNumberLoginData,
        LoginResponse
      >(userApiUrl.login, data);

      // Map the response data to an AuthUser object
      const authUser = mapUserData(response.data);

      // Set user cache
      user.login(authUser);

      mixpanelService.identify();

      moengageService.identify();

      trackLoginSuccess();

      if (user.isDataVerified && user.isPhoneNumberVerified) {
        trackLoginSuccessMobileFullyVerified();
      } else {
        trackLoginSuccessMobileUnverified();
      }

      // Return the authenticated user
      return authUser;
    } catch (error) {
      // Translate the error message
      const translatedError = this.translatePhoneError(error);

      throw translatedError;
    }
  }

  private translatePhoneError(error: any): string {
    if (error.response?.status === 401) {
      return trans("auth.invalidPhoneNumberOrPassword");
    } else if (error.response?.status === 500) {
      return trans("auth.serverError");
    } else {
      return parseError(error);
    }
  }

  /**
   * Login/Signup by Google
   */
  public async signupByGoogle(data: GoogleSignInData): Promise<AuthUser> {
    try {
      const signupV3 = true;
      // Make the HTTP post request to the server with the user token
      const response = await this.http.post<
        {
          code: string;
          provider: "GOOGLE";
          signUpV3?: boolean;
          selectedMarket: string;
        },
        any
      >(userApiUrl.socialSignup, {
        code: data.token,
        provider: "GOOGLE",
        signUpV3: data.accessFrom === "signup" ? signupV3 : undefined,
        selectedMarket: getCountryIsoCode3FromIsoCode2(
          countryAtom.get("market"),
        ),
      });

      const authUser = mapUserData({
        accessToken: response.data.accessToken,
        ...response.data.user,
      });

      // Set user cache
      user.login(authUser);

      mixpanelService.identify();

      const utmData = this.utmDataRepository.getData();

      if (data.accessFrom === "signup") {
        trackSignUpByGoogleSuccess(
          {
            signupV3,
          },
          utmData,
        );
      } else {
        trackLoginSuccess();

        trackLoginSuccessByGoogle();
      }

      // Return the authenticated user
      return authUser;
    } catch (error) {
      // Translate the error message
      const translatedError = this.translateGoogleError(error);

      throw translatedError;
    }
  }

  public async completeGoogleSignup(
    data: CompleteGoogleSignupData,
  ): Promise<AuthUser> {
    try {
      const response = await this.http.post<CompleteGoogleSignupData, any>(
        userApiUrl.completeGoogleSignup,
        data,
      );

      const authUser = mapUserData(response.data);

      // Set user cache
      user.login(authUser);

      mixpanelService.identify();

      moengageService.trackEvent(
        UserLoginActivityEvents.User_SignUp_Completed,
        {
          user_id: user.id,
          email: user.email,
          phone_number: user.phoneNumber,
          calling_code: user.callingCode,
          name: user.fullName,
          selectedMarket: user.selectedMarket,
        },
      );

      trackGoogleTagSignUpEvent();

      return authUser;
    } catch (error) {
      // Translate the error message
      const translatedError = this.translateGoogleError(error);

      throw translatedError;
    }
  }

  private translateGoogleError(error: any): string {
    if (error.response?.data?.errorCode === "phone-number-already-taken") {
      return trans("auth.phoneNumberAlreadyTaken");
    } else if (
      error.response?.data?.errorCode === "UNAUTHORIZED_REGISTRATION_DISABLED"
    ) {
      return trans("auth.socialRegistrationDisabled");
    }

    if ([401, 403].includes(error.response?.status)) {
      return trans("auth.invalidLoginData");
    } else if (error.response?.status === 500) {
      return trans("auth.serverError");
    } else {
      return parseError(error);
    }
  }

  /**
   * Forget Password
   */
  public async forgetPassword(data: ForgetPasswordData): Promise<void> {
    try {
      // Make the HTTP post request to the server with the forget password form data
      await this.http.patch<ForgetPasswordData, void>(
        userApiUrl.forgetPassword,
        data,
      );
    } catch (error) {
      // Translate the error message
      const translatedError = this.translateForgetPasswordError(error);

      throw translatedError;
    }
  }

  private translateForgetPasswordError(error: any): string {
    if (error.response?.status === 401) {
      return trans("auth.emailNotFound");
    } else if (error.response?.status === 500) {
      return trans("auth.serverError");
    } else if (error?.response?.status === 404) {
      return trans("auth.emailNotFound");
    } else {
      return parseError(error);
    }
  }

  /**
   * Reset Password
   */
  public async resetPassword({
    confirmPassword,
    id,
    password,
    resetToken,
  }: ResetPasswordData): Promise<void> {
    // Make the HTTP post request to the server with the reset password form data
    await this.http.patch<
      Pick<ResetPasswordData, "password" | "confirmPassword">,
      void
    >(userApiUrl.resetPassword(id, resetToken), {
      password,
      confirmPassword,
    });
  }

  /**
   * Create new user
   */
  public async signupByEmail(data: RegisterData): Promise<AuthUser> {
    const signupV3 = true;

    // Make the HTTP post request to the server with the register form data
    const response = await this.http.post<
      RegisterData & { signUpV3?: boolean },
      AuthUser
    >(userApiUrl.register, {
      ...data,
      signUpV3: signupV3,
    });

    // Map the response data to an AuthUser object
    const authUser = mapUserData(response.data);

    // Set user cache
    user.login(authUser);

    mixpanelService.identify();

    moengageService.trackEvent(UserLoginActivityEvents.User_SignUp_Completed, {
      user_id: user.id,
      email: user.email,
      phone_number: user.phoneNumber,
      calling_code: user.callingCode,
      name: user.fullName,
      selectedMarket: user.selectedMarket,
    });

    const utmData = this.utmDataRepository.getData();

    trackSignUpByEmailSuccess(
      {
        signupV3,
      },
      utmData,
    );

    trackGoogleTagSignUpEvent();

    // Return the authenticated user
    return authUser;
  }

  /**
   * Get email OTP
   */
  public async getEmailOTP(data: GetEmailOTPData): Promise<OTPData> {
    try {
      const response = await this.http.post<GetEmailOTPData, OTPData>(
        userApiUrl.requestEmailOTP,
        data,
      );

      trackEmailVerificationRequested(response.data);

      return response.data;
    } catch (error) {
      trackEmailVerificationRequestError(parseError(error));
      throw error;
    }
  }

  /**
   * Verify Email
   */
  public async verifyEmail(data: VerifyEmailData): Promise<AuthUser> {
    try {
      const response = await this.http.post<VerifyEmailData, AuthUser>(
        userApiUrl.verifyEmailOtp,
        data,
      );

      // Map the response data to an AuthUser object
      const authUser = mapUserData(response.data);

      // update user cache
      user.update(authUser);

      trackEmailVerificationSuccess(data);

      // Return the authenticated user
      return authUser;
    } catch (error) {
      trackEmailVerificationFailed(data, error);
      throw error;
    }
  }

  /**
   * Get email OTP
   */
  public async getPhoneOTP(data: GetPhoneOTPData): Promise<OTPData> {
    try {
      const response = await this.http.patch<
        GetPhoneOTPData,
        { data: OTPData }
      >(userApiUrl.requestOTP, data); // temporary fix response.data is giving an error that data does not exist

      trackPhoneNumberVerificationRequested(data, response.data);

      return response.data;
    } catch (error) {
      trackPhoneNumberVerificationRequestError(data, error);
      throw error;
    }
  }

  /**
   * Verify Phone Number
   */
  public async verifyPhoneNumber(
    data: VerifyPhoneNumberData,
  ): Promise<AuthUser> {
    try {
      // Make the HTTP post request to the server with the register form data
      const response = await this.http.post<VerifyPhoneNumberData, AuthUser>(
        userApiUrl.verifyPhoneNumber,
        data,
      );

      // Map the response data to an AuthUser object
      const authUser = mapUserData(response.data);

      // update user cache
      user.update(authUser);

      trackPhoneNumberVerificationSuccess({
        callingCode: data.callingCode,
        phoneNumber: data.phoneNumber,
      });

      const signupV3 = true;

      trackSignUpVerificationSuccess({
        signupV3,
      });

      // Return the authenticated user
      return authUser;
    } catch (error) {
      trackPhoneNumberVerificationFailed(data, error);
      throw error;
    }
  }

  /**
   * Verify National Id
   */
  public async verifyNationalId(_data: VerifyNationalIdData): Promise<void> {
    throw new Error("Method not implemented.");
  }

  /**
   * Log out
   */
  public async logout(): Promise<void> {
    moengageService.destroySession();

    // Remove user data from cache
    user.logout();

    // Track Logout Event
    moengageService.destroySession();

    // Clear local storage data
    localStorage.clear();

    // will there be an http request ? as if now there's none.
  }

  /**
   * Update JWT token using refresh token
   */
  public async updateJwt(_data: UpdateJwtData): Promise<string> {
    throw new Error("Method not implemented.");
  }

  /**
   * Rotate refresh token by generating a new one
   */
  public async rotateRefreshToken(
    _data: RotateRefreshTokenData,
  ): Promise<string> {
    throw new Error("Method not implemented.");
  }

  /**
   * Update User Info
   */
  public async completeUserProfile(
    data: CompleteUserProfileData,
  ): Promise<AuthUser> {
    try {
      // Make the HTTP post request to the server with the register form data
      const response = await this.http.patch<
        CompleteUserProfileData,
        { data: AuthUser }
      >(userApiUrl.updateUserProfile, data);

      // Map the response data to an AuthUser object
      const authUser = mapUserData(response.data);

      // update user cache using .login not .update, as .update preserves the old jwt token.
      user.login(authUser);

      moengageService.trackEvent(
        UserLoginActivityEvents.User_Profile_Completed,
      );

      trackSignupCompleteProfileSuccessfully({
        ...data,
        signupV3: true,
      });

      trackGoogleTagCompleteSignUpEvent({
        ...data,
        signupV3: true,
      });
      // Return the authenticated user
      return authUser;
    } catch (error) {
      trackEvent(UserActivityEvents.SIGN_UP_COMPLETE_PROFILE_FAILED);
      throw error;
    }
  }
}
