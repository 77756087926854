import { CatalogedProduct } from "@features/catalog/domain";
import { ProductPricingEntity } from "@features/checkout/domain";

export const mergeProductPricingDetails = (
  products: CatalogedProduct[],
  productsPricingDetails: ProductPricingEntity[],
) => {
  return products.map(product => ({
    ...product,
    pricingDetails: productsPricingDetails.find(
      detail => detail.sku === product.sku,
    ),
  }));
};
