import {
  QuestionnaireEntity,
  QuestionnaireName,
  QuestionnaireRepositoryContract,
  QuestionnaireSubmissionPayload,
} from "@features/questionnaire/domain";
import { BaseRepository } from "@features/shared/data";
import { QUESTIONNAIRE_API } from "../constants";
import { mapQuestionnaire } from "../mappers";
import { QuestionnaireModel } from "../types/questionnaire.model";

export class QuestionnaireRepository
  extends BaseRepository
  implements QuestionnaireRepositoryContract
{
  public async getQuestionnaire(
    name: QuestionnaireName,
  ): Promise<QuestionnaireEntity> {
    const { data } = await this.http.get<QuestionnaireModel>(
      QUESTIONNAIRE_API.getQuestionnaireQuestions(name),
    );

    return mapQuestionnaire(data);
  }

  public async submitQuestionnaireAnswers(
    submission: QuestionnaireSubmissionPayload,
  ): Promise<void> {
    await this.http.post<
      QuestionnaireSubmissionPayload,
      { data: { message: string } }
    >(
      QUESTIONNAIRE_API.submitQuestionnaireAnswers(submission.name),
      submission,
    );
  }
}
