export const storesApiUrl = {
  // General Stores API
  userStores: "/users/stores",
  connectStore: "/stores",
  disconnectStore: (storeId: string) => `/stores/${storeId}`,
  // Products To Store API
  addProductToStore: (productId: string, storeId: string) =>
    `/stores/${storeId}/products/${productId}`,
  // Easy Orders API
  getEasyOrdersApiKey: "/api-keys",
  generateEasyOrdersApiKey: "/api-keys/generate",
  // Dukan API
  getDukanStore: "/dukan",
  // Create dukan store
  createDukanStore: "/dukan",
  // Reactivate dukan store
  reactivateDukanStore: "/dukan",
};

export const wooCommercePluginUrlPath =
  "/wp-admin/plugin-install.php?s=taager%2520addon&tab=search&type=term";

export const lightFunnelsUpdateStoreShippingVideoUrl =
  "https://www.youtube.com/embed/XbocVqH7fB8?si=AFwxlGi5SaWm_ucy&amp;";
