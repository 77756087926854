import { mixPanelEvent } from "@features/activities/data";
import { CatalogedProduct } from "@features/catalog/domain";
import { Store, StoreProvider } from "@features/stores/domain";

export enum StoresEvents {
  // Link Store
  CLICK_LINK_STORE_BUTTON = "click_link_store_button",
  CLICK_LINK_STORE_SUCCESSFUL_MESSAGE = "click_link_store_successful_message",
  CLICK_LINK_STORE_FAILURE_MESSAGE = "click_link_store_failure_message",
  // Unlink Store
  CLICK_UNLINK_STORE_SUCCESSFUL_MESSAGE = "click_unlink_store_successful_message",
  CLICK_UNLINK_STORE_BUTTON = "click_unlink_store_button",
  CLICK_UNLINK_STORE_FAILURE_MESSAGE = "click_unlink_store_failure_message",
  ADD_TO_STORE_BUTTON_CLICKED = "add_to_store_clicked",
  ADD_TO_STORE_SUCCESS = "add_to_store_success",
  ADD_TO_STORE_FAILURE = "add_to_store_fail",
}

export enum ProductSourcePage {
  CATALOG = "catalog",
}

type AddToStoreData = {
  provider: StoreProvider;
  productSourcePage: ProductSourcePage;
  product: CatalogedProduct;
};

export function trackConnectingStore(provider: StoreProvider) {
  mixPanelEvent(StoresEvents.CLICK_LINK_STORE_BUTTON, {
    provider,
  });
}

export function trackStoreConnectionSuccess(data: {
  provider: StoreProvider;
  storeId: string;
}) {
  mixPanelEvent(StoresEvents.CLICK_LINK_STORE_SUCCESSFUL_MESSAGE, {
    provider: data.provider,
    storeId: data.storeId,
  });
}

export function trackStoreConnectionFailure(data: {
  provider: StoreProvider;
  error: string;
}) {
  mixPanelEvent(StoresEvents.CLICK_LINK_STORE_FAILURE_MESSAGE, {
    provider: data.provider,
    error: data.error,
  });
}

export function trackDisconnectStoreButtonClicked(store: Store) {
  mixPanelEvent(StoresEvents.CLICK_UNLINK_STORE_BUTTON, {
    provider: store.provider,
    storeId: store.id,
  });
}

export function trackDisconnectStoreSuccess(store: Store) {
  mixPanelEvent(StoresEvents.CLICK_UNLINK_STORE_SUCCESSFUL_MESSAGE, {
    provider: store.provider,
    storeId: store.id,
  });
}

export function trackDisconnectStoreFailure(store: Store, error: string) {
  mixPanelEvent(StoresEvents.CLICK_UNLINK_STORE_FAILURE_MESSAGE, {
    provider: store.provider,
    storeId: store.id,
    error,
  });
}

export function trackAddToStoreButtonClicked(data: AddToStoreData) {
  mixPanelEvent(StoresEvents.ADD_TO_STORE_BUTTON_CLICKED, data);
}

export function trackAddToStoreSuccess(data: {
  provider: StoreProvider;
  productSourcePage: ProductSourcePage;
  product: CatalogedProduct;
}) {
  mixPanelEvent(StoresEvents.ADD_TO_STORE_SUCCESS, data);
}

export function trackAddToStoreFailure(data: AddToStoreData) {
  mixPanelEvent(StoresEvents.ADD_TO_STORE_FAILURE, data);
}
