import { getCurrencyCodeByCountry } from "@features/country/data";
import {
  OrderModel,
  OrderProductModel,
  OrdersListModel,
} from "@features/orders/data";
import {
  OrderEntity,
  OrderLineEntity,
  OrderProductEntity,
  OrdersListEntity,
} from "@features/orders/domain";

function mapOrderProduct(product: OrderProductModel): OrderProductEntity {
  return {
    name: product?.name,
    price: product?.productPrice,
    quantity: product?.productQty,
    profit: product?.productProfit,
    productId: product?.productObjectId,
  };
}

function mapOrderLines(order: OrderModel): OrderLineEntity[] {
  const orderLines = order.orderLines || [
    mapOrderProduct((order as any).product),
  ];

  return orderLines as OrderLineEntity[];
}

export function mapOrdersList(
  data: OrdersListModel,
  pageSize: number,
  type: OrderEntity["type"],
): OrdersListEntity {
  return {
    hasNextPage: data.orders.length >= pageSize,
    count: data.orders.length,
    orders: data.orders.map(order => ({
      ...order,
      id: order.orderID || order.orderId,
      orderLines: mapOrderLines(order),
      type,
      currency: getCurrencyCodeByCountry(order.country),
      totalQuantity: order?.product?.productQty,
      returnFee: order.returnShippingCost,
    })) as OrderEntity[], // For now
  };
}
