import { Category } from "@features/catalog/domain/entities";
import { mediaUrl } from "@features/shared/data";
import { CommercialCategoryEntity } from "../models/commercial-category.model";

export function mapCommercialCategory(
  param: CommercialCategoryEntity,
): Category {
  return {
    id: param.categoryId,
    name: {
      ar: param.arabicName,
      en: param.englishName,
    },
    sortOrder: param.sorting,
    icon: mediaUrl(
      `commercial-categories/icons/${param.icon || "default.svg"}`,
    ),
    isFeatured: param.featured,
    children: param.children?.map(mapCommercialCategory) || [],
  };
}
