import { LeadListFilters, leadsApiUrl } from "@features/leads/data";
import {
  LeadListResponseEntity,
  LeadsRepositoryContract,
} from "@features/leads/domain";
import { BaseRepository } from "@features/shared/data";
import { heavyObject } from "@features/shared/presentation";

export class LeadsRepository
  extends BaseRepository
  implements LeadsRepositoryContract
{
  public async list(filter: LeadListFilters): Promise<LeadListResponseEntity> {
    const response = await this.http.get<LeadListResponseEntity>(
      leadsApiUrl.list,
      { params: heavyObject(filter) },
    );

    return {
      count: response.data.count,
      data: response.data.data,
    };
  }
}
