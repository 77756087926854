import { gTagEvent, mixPanelEvent } from "@features/activities/data";
import { getUserCountryLocation } from "@features/country/data";
import { getUserPlatform } from "@features/shared/data";
import { user } from "@features/user/data";

export enum UserLoginActivityEvents {
  // Google Tag Manager Events
  LOGIN_SUCCESS = "sign_in",

  // Mixpanel Events
  LOGIN_SUCCESS_BY_EMAIL = "login_email",
  LOGIN_SUCCESS_MOBILE_FULLY_VERIFIED = "login_mobile_fully_verified",
  LOGIN_SUCCESS_MOBILE_UNVERIFIED = "login_mobile_unverified",
  LOGIN_SUCCESS_MOBILE_LEGACY_USER = "login_mobile_legacy_user",
  LOGIN_SUCCESS_BY_GOOGLE = "login_google",

  // MoEngage Events
  User_SignUp_Completed = "User_SignUp_Completed",
  User_Profile_Completed = "User_Profile_Completed",
}

export function trackLoginEmailSuccess() {
  const payload = {
    status: "logged in",
  };

  mixPanelEvent(UserLoginActivityEvents.LOGIN_SUCCESS_BY_EMAIL, payload);
}

export function trackLoginSuccess() {
  const payload = {
    "Taager ID": user.id,
    Platform: getUserPlatform(),
    "User Location": getUserCountryLocation(),
  };

  gTagEvent(UserLoginActivityEvents.LOGIN_SUCCESS, payload);
}

export function trackLoginSuccessMobileFullyVerified() {
  const payload = {
    status: "logged in",
  };

  mixPanelEvent(
    UserLoginActivityEvents.LOGIN_SUCCESS_MOBILE_FULLY_VERIFIED,
    payload,
  );
}

export function trackLoginSuccessByGoogle() {
  const payload = {
    status: "logged in",
    login_using: "Google",
  };

  mixPanelEvent(UserLoginActivityEvents.LOGIN_SUCCESS_BY_GOOGLE, payload);
}

export function trackLoginSuccessMobileUnverified() {
  const payload = {
    status: "logged in",
  };

  mixPanelEvent(
    UserLoginActivityEvents.LOGIN_SUCCESS_MOBILE_UNVERIFIED,
    payload,
  );
}
