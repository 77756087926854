import { country } from "@features/country/data";
import {
  completedUrlsPages,
  getCountryCodeFromUrl,
} from "@features/shared/presentation";
import { navigateTo, NAVIGATING } from "@taager/react-router";
import { GenericObject } from "@taager/reinforcements";

function hardNavigation(url: string, params?: GenericObject) {
  const fullUrl = new URL(window.location.href);

  fullUrl.pathname = `/${country.shortCode || getCountryCodeFromUrl()}${url}`;

  if (params) {
    fullUrl.search = new URLSearchParams(params).toString();
  }

  window.location.href = fullUrl.toString();

  // this return is used to stop rendering the page component as this function may be middleware
  return NAVIGATING;
}

export function navigate(url: string, params?: GenericObject) {
  const route = url.split("?")[0];

  if (completedUrlsPages.includes(route)) {
    return navigateTo(url);
  }

  return hardNavigation(url, params);
}
