import {
  ProductPricingEntity,
  ProductPricingValidityDetails,
} from "@features/checkout/domain";
import { ProductPricingModel } from "../types";

export function mapProductPricingPayload(
  product: ProductPricingModel,
): ProductPricingEntity {
  return {
    sku: product.productId,
    price: product.finalPrice,
    profit: product.finalProfit,
    netProfit: product.netProfitPerItem,
    vat: product.vat,
    finalPricePerItem: product.finalPricePerItem,
    finalProfitPerItem: product.finalProfitPerItem,
    originalPricePerItem: product.originalPricePerItem,
    validity: {
      isValid: product.validity.isValid,
      details: product.validity.details.includes("minimum")
        ? ProductPricingValidityDetails.MINIMUM
        : product.validity.details.includes("maximum")
          ? ProductPricingValidityDetails.MAXIMUM
          : undefined,
    },
    discount: product.discount,
  };
}
