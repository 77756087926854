import { sessionDriver } from "@features/drivers/data";
import { BaseRepository } from "@features/shared/data";
import { UserUTMData, UTMDataRepositoryContract } from "@features/user/domain";
import user from "user";
import { userApiUrl, UTM_SESSION_KEY } from "../constants";

export class UTMDataRepository
  extends BaseRepository
  implements UTMDataRepositoryContract
{
  public isDetected(): boolean {
    return Boolean(sessionDriver.get(UTM_SESSION_KEY, false));
  }

  public async send(): Promise<void> {
    if (!user.isLoggedIn || !this.isDetected()) {
      return;
    }

    await this.http.post(userApiUrl.addUserUTMData(user.id), this.getData());
  }

  public setData(utmData: UserUTMData): this {
    sessionDriver.set(UTM_SESSION_KEY, utmData);

    return this;
  }

  public getData(): UserUTMData | null {
    return sessionDriver.get(UTM_SESSION_KEY, null);
  }
}
