import { DistrictEntity } from "@features/country/domain/entities/district.entity";
import { ZoneEntity } from "@features/country/domain/entities/zone.entity";
import { BaseRepository } from "@features/shared/data";
import type {
  CountriesRepositoryContract,
  Country,
  CountryCode,
} from "../../domain";
import { COUNTRIES_CACHE_KEY, countriesApiUrl } from "../constants";
import { mapCountry } from "../mappers/country-mapper";
import { mapDistrict } from "../mappers/district-mapper";
import { mapProvince } from "../mappers/province-mapper";
import { mapZone } from "../mappers/zone-mapper";
import { CountryModel } from "../models/country.model";
import { DistrictModel } from "../models/district.model";
import { ProvinceModel } from "../models/province.model";
import { ZoneModel } from "../models/zone.model";

export class CountriesRepository
  extends BaseRepository
  implements CountriesRepositoryContract
{
  /**
   * Get a list of countries
   */
  public async list(): Promise<Country[]> {
    return this.cacheable(COUNTRIES_CACHE_KEY, async () => {
      const response = await this.http.get<{
        data: CountryModel[];
      }>(countriesApiUrl.list);

      const countries = response.data.data.map(mapCountry);

      return countries;
    });
  }

  public async updateSelectedMarket(iso3: string): Promise<void> {
    await this.http.patch(countriesApiUrl.updateMarket, {
      selectedMarket: iso3,
    });
  }

  public async getCountryProvinces(countryCode: CountryCode) {
    const response = await this.http.get<{
      data: { provinces: ProvinceModel[] };
    }>(countriesApiUrl.getCountryProvinces(countryCode));

    const provinces = response.data.data.provinces
      .filter(province => province.isActive)
      .map(mapProvince);

    return provinces;
  }

  public async getProvinceZones(
    countryCode: CountryCode,
    provinceId: string,
  ): Promise<ZoneEntity[]> {
    const response = await this.http.get<{
      data: ZoneModel[];
    }>(countriesApiUrl.getProvinceZones(countryCode, provinceId));

    return response.data.data.map(mapZone);
  }

  public async getZoneDistricts(
    countryCode: CountryCode,
    provinceId: string,
    zoneId: string,
  ): Promise<DistrictEntity[]> {
    const response = await this.http.get<{
      data: DistrictModel[];
    }>(countriesApiUrl.getZoneDistricts(countryCode, provinceId, zoneId));

    return response.data.data.map(mapDistrict);
  }
}
