import { CatalogedProduct } from "@features/catalog/domain";
import { mapProductPricingPayload } from "@features/checkout/data/mappers";
import {
  CalculateOrderCostEntity,
  CalculateOrderCostPayload,
  CheckoutRepositoryContract,
  CreateOrderPayload,
  OrderPriceCalculationEntity,
  ProductPricingPayload,
} from "@features/checkout/domain";
import { country } from "@features/country/data";
import { BaseRepository, parseError } from "@features/shared/data";
import { user } from "@features/user/data";
import { UTMDataRepositoryContract } from "@features/user/domain";
import {
  trackOrderCompletion,
  trackPlaceOrderInitiate,
  trackPlaceOrderResponse,
} from "../events";
import { CHECKOUT_API } from "./../constants";
import {
  CreateOrderModelResponse,
  GetProductsPricingModelResponse,
} from "./../types";

export class CheckoutRepository
  extends BaseRepository
  implements CheckoutRepositoryContract
{
  public constructor(protected utmDataRepository: UTMDataRepositoryContract) {
    super();
  }

  /**
   * Calculate Order Prices
   */
  public async calculateOrderPrices(
    payload: CalculateOrderCostPayload,
  ): Promise<CalculateOrderCostEntity> {
    const { data } = await this.http.post<
      CalculateOrderCostPayload,
      { data: CalculateOrderCostEntity }
    >(CHECKOUT_API.calculateOrderCost, payload);

    return data.data;
  }

  /**
   * Create new order
   */
  public async createOrder(
    payload: CreateOrderPayload,
    products: CatalogedProduct[],
  ): Promise<string> {
    trackPlaceOrderInitiate();
    trackOrderCompletion({
      products,
      productQuantities: payload.productQuantities,
      paymentAmount: payload.cashOnDelivery,
    });

    payload.isUtmAttributedOrder = this.utmDataRepository.isDetected();

    try {
      const { data } = await this.http.post<
        CreateOrderPayload,
        CreateOrderModelResponse
      >(CHECKOUT_API.createOrder, payload);

      trackPlaceOrderResponse(payload, products);

      return data.order.orderID;
    } catch (e) {
      parseError(e);
      const errorMessage = parseError(e);

      trackPlaceOrderResponse(payload, products, {
        message: errorMessage,
        object: (e as any).response?.data,
      });

      throw new Error(errorMessage);
    }
  }

  /**
   * Get Products Pricing
   */
  public async getProductsPricing(
    data: ProductPricingPayload,
  ): Promise<OrderPriceCalculationEntity> {
    const response = await this.http.post<
      ProductPricingPayload,
      GetProductsPricingModelResponse
    >(CHECKOUT_API.getProductsPricing, {
      country: country.code,
      taagerId: user.id,
      ...data,
    });

    const products = response.data.data.products.map(mapProductPricingPayload);

    return {
      ...response.data.data,
      products,
    };
  }
}
