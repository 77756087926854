import { CurrencyCode } from "@features/shared/domain";
import { Country, CountryCode } from "../../domain";
import type { CountryModel } from "../models/country.model";
import { getCountryFlag } from "./../utils";

export function mapCountry(country: CountryModel): Country {
  return {
    currencyCode: country.currencyIsoCode,
    isoCode2: country.countryIsoCode2,
    isoCode3: country.countryIsoCode3,
    flag: getCountryFlag(country.countryIsoCode3),
    vat: country.vat,
  };
}

const countryCurrencyMap: Record<CountryCode, CurrencyCode> = {
  SAU: CurrencyCode.SAR,
  OMN: CurrencyCode.OMR,
  ARE: CurrencyCode.AED,
  IRQ: CurrencyCode.IQD,
  KWT: CurrencyCode.KWD,
  EGY: CurrencyCode.EGP,
  TNT: CurrencyCode.SAR,
};

export function getCurrencyCodeByCountry(
  countryCode: CountryCode,
): CurrencyCode {
  return countryCurrencyMap[countryCode];
}
