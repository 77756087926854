import { ProductSale } from "../models/cataloged-product.model";

/**
 * Trims the last character (Z) from an ISO timestamp string.
 * ISO timestamps from APIs often end with 'Z' to indicate UTC time.
 * The Date constructor handles timestamps with or without the Z,
 * so we trim it for consistency.
 *
 * Example:
 * Input: "2024-01-01T00:00:00Z"
 * Output: "2024-01-01T00:00:00"
 */
export const trimTimestamp = (timestamp: string): string => {
  return timestamp.toString().slice(0, -1);
};

export const isSaleActive = (sale: ProductSale): boolean => {
  const now = new Date().getTime();

  if (sale.saleStartsAt) {
    const startTime = new Date(trimTimestamp(sale.saleStartsAt)).getTime();

    if (startTime >= now) {
      return false;
    }
  }

  if (sale.saleExpiresAt) {
    const expiryTime = new Date(trimTimestamp(sale.saleExpiresAt)).getTime();

    if (expiryTime <= now) {
      return false;
    }
  }

  return true;
};
