import { CartRepositoryContract } from "@features/cart/domain";
import { BaseRepository } from "@features/shared/data";
import { CART_API } from "../constants";

export class CartRepository
  extends BaseRepository
  implements CartRepositoryContract
{
  public async addProductToCart(
    id: string,
    desiredPrice: number,
    quantity: number,
  ): Promise<void> {
    await this.http.patch(CART_API.ADD_TO_CART_WITH_CUSTOM_PRICE_URL, {
      productId: id,
      price: desiredPrice,
      quantity,
    });
  }
}
