export type ProductPricingEntity = {
  sku: string;
  price: number;
  profit: number;
  netProfit: number;
  vat?: number;
  finalPricePerItem: number;
  finalProfitPerItem: number;
  originalPricePerItem: number;
  validity: {
    isValid: boolean;
    details?: ProductPricingValidityDetails;
  };
  discount?: {
    type: ProductDiscountType;
    price: number;
    profit: number;
  };
};

export enum ProductPricingValidityDetails {
  MINIMUM = "MinimumPriceError",
  MAXIMUM = "MaximumPriceError",
}

export enum ProductDiscountType {
  ADDITIONAL_QUANTITY = "AdditionalQuantity",
}
